import React from 'react'
import ME from "../../assets/me.jpeg"
import "./testmonial.css"
import {   Pagination } from 'swiper';
import client1 from "../../assets/client1.jpg"
import client2 from "../../assets/client2.jpg"
import client3 from "../../assets/client3.jpg"
import client4 from "../../assets/client4.jpg"
import client5 from "../../assets/client5.png"
import client6 from "../../assets/client6.webp"
import client7 from "../../assets/client7.webp"

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
function Testimonial() {

  return (
    <section id="testimonial">
      <h5>Reviews From Clients</h5>
      <h2>Testimonials</h2>
      <Swiper className="container testimonial_container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
     
      >

        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client7} alt="" />
          </div>
          <h5 className='client_name'>artagon_</h5>
            <small className='client_review'>Amazing guy to work with, very communicative, and even proactively gives suggestions which I hadn't even thought about. You won't be able to hire him because he will be working for me 24/7 from now. Hidden gem 🔷</small>

        </SwiperSlide>
        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client5} alt="" />
          </div>
          <h5 className='client_name'>danieltjee_</h5>

            <small className='client_review'>The seller got started on my order very quickly after reaching out to him. He helped me set up a redirect page with JS, PHP, and a simple HTML body, exactly what I had asked for. The quality of the work is excellent and the speed at which he was able to deliver this custom page is out of this world. I got my final product within an hour after ordering! If I ever need help with my website in the future, I'll come back to this seller.</small>

        </SwiperSlide>
        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client6} alt="" />
          </div>
          <h5 className='client_name'>rodiloo</h5>

            <small className='client_review'>arhamkhan8281 Exceeded my expectation, I had an issue which I thought was related to react routing, I contacted him and he started investigating it. After only 2 hours he came back with a solution which was not what I thought was and he even helped me fix another bug I had in my source code. I definitely recommend him!</small>

        </SwiperSlide>
        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client2} alt="" />
          </div>
          <h5 className='client_name'>mxhrhu</h5>

            <small className='client_review'>Arham Khan is a very good developer dedicated to his work. He completed delivery within a few hours although I wasn't expecting it so fast. I will really recommend him. Thank You so much brother for your quick and outclass work </small>

        </SwiperSlide>

        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client4} alt="" />
          </div>
          <h5 className='client_name'>moadh7</h5>
            <small className='client_review'>True talent ! Amazing work Will continue working with Arham he is truly a great dev</small>

        </SwiperSlide>

        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client4} alt="" />
          </div>
          <h5 className='client_name'>moadh7</h5>
            <small className='client_review'>Very good professional! always attentive and understanding for the Improvement of the project. highly recomended!</small>

        </SwiperSlide>

        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client1} alt="" />
          </div>
          <h5 className='client_name'>duddelas1</h5>

            <small className='client_review'>it was too perfect mate thanks hope to work again soon</small>

        </SwiperSlide>
        <SwiperSlide className='testimonial'>
          <div className="client_avatar">
            <img src={client4} alt="" />
          </div>
          <h5 className='client_name'>moadh7</h5>

            <small className='client_review'>Very talented developer !! He went above and beyond to deliver such an amazing work Will hire him again for sure</small>

        </SwiperSlide>
       
        
       
   
      </Swiper>
   
      
    </section>
  )
}

export default Testimonial
