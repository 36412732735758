import React from 'react'
import ME from "../../assets/me.jpeg"
import {FaAward} from "react-icons/fa"
import {FiUsers} from "react-icons/fi"
import {VscFolderLibrary} from "react-icons/vsc"
import "./about.css"


function About() {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about_container'>
        <div className="about_me">
          <div className="about_me_image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
          <article className='about_card'>
              <FaAward className='about_icon'/>
              <h5>3+ Experience</h5>
              <small> Years Working</small>
            </article>
            
            <article className='about_card'>
              <FiUsers className='about_icon'/>
              <h5>Clients</h5>
              <small>20+ World Wide</small>
            </article>
          
       
            <article className='about_card'>
              <VscFolderLibrary className='about_icon'/>
              <h5>Projects</h5>
              <small>80+ completed</small>
            </article>
        
       
          
          </div>
          <p>
          I WILL DO WEBSITE DEVELOPMENT AND WEB DESIGNING If you are looking for High Quality Website Design and Development Services to increase your Business Presence Or Start Selling Your Product Online. I create hand coded, cutting edge,User friendly Responsive websites. I will help you from start to finish. We will Provide you: -High Quality Code with fully Responsive Design -Mobile,Tablet and Desktop Friendly -Search Engine Friendly Markup and URL structure -Unlimited revisions till 100% satisfaction -Easy to use admin panel and User Manual
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>

      </div>
      
    </section>

  )
}

export default About
