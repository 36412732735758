import React from 'react'
import {BsLinkedin} from "react-icons/bs";
import {FaGithub} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {SiFiverr} from "react-icons/si"


const HeaderSocials = () => {
  return (
    <div className="header_socials">
        <a href="https://www.linkedin.com/in/arham-khan-9ba634231/" target="_blank" className=''><BsLinkedin/></a>
        <a href="https://github.com/Arham47" target="_blank" className=''><FaGithub/></a>
        <a href="https://www.facebook.com/fraudibanda" target="_blank" className=''><FaFacebook/></a>
        <a href="https://www.fiverr.com/users/arhamkhan8281" target="_blank"><SiFiverr/></a>
    </div>
  )
}

export default HeaderSocials
