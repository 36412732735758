import React from 'react'
import IMG from "../../assets/bg13.jpg"
import Filehosting from "../../assets/filhosting.png"
import Movie from "../../assets/movie.png"
import emissify from "../../assets/emissify.png"
import ecommerce from "../../assets/ecommerce.png"
import Telegram from "../../assets/telegram.png"
import amazona from "../../assets/amazona.png"
import "./portfolio.css"

function portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={Filehosting} alt="" />
          </div>
          <h3>This File Hosting website</h3>
          <div className="portfolio_item_cta">
          <a href="" className='btn' onClick={(e)=>alert("It's Client Project You Can Watch Live Demo ")}> Github</a>
          <a href="https://www.incbay.net" target="_blank" className='btn btn-primary'> Live Demo</a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={Movie} alt="" />
          </div>
          <h3>Watch Movie Online</h3>
          <div className="portfolio_item_cta">
          <a href="" className='btn' onClick={(e)=>alert("It's Client Project You Can Watch Live Demo ")}> Github</a>
          <a href="https://www.ibomma.uk" target="_blank"  className='btn btn-primary'> Live Demo</a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={emissify} alt="" />
          </div>
          <h3>TonCo2 Calculator</h3>
          <div className="portfolio_item_cta">
          <a  className='btn' onClick={(e)=>alert("It's Client Project You Can Watch Live Demo ")}> Github</a>
          <a href="https://www.emissify.com" target="_blank"  className='btn btn-primary'> Live Demo</a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={ecommerce} alt="" />
          </div>
          <h3>E-commerce Website</h3>
          <div className="portfolio_item_cta">
          <a  className='btn' onClick={(e)=>alert("It's Client Project You Can Watch Live Demo ")}> Github</a>
          <a href="https://www.hs-wears.com" target="_blank" className='btn btn-primary'> Live Demo</a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={Telegram} alt="" />
          </div>
          <h3>Telegram E-commerce Bot</h3>
          <div className="portfolio_item_cta">
          <a href="https://github.com/Arham47/TelegramEcommerceBot" target="_blank" className='btn'> Github</a>
          <a href="#portfolio" className='btn btn-primary' onClick={(e)=>alert("The Project Is Not Live You can watch GitHub Code")}> Live Demo</a>
          </div>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item_image">
            <img src={amazona} alt="" />
          </div>
          <h3>Amazona</h3>
          <div className="portfolio_item_cta">
          <a href="https://github.com/Arham47/Amazona_React_NodeJs_EcommerenceWebSite" target="_blank"className='btn'> Github</a>
          <a href="#portfolio" onClick={(e)=>alert("The Project Is Not Live You can watch GitHub Code")} className='btn btn-primary'> Live Demo</a>
          </div>
        </article>
      
       
      </div>
    </section>
  )
}

export default portfolio